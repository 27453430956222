import backgroundImage from 'assets/img/push/phone.svg';
import {Text} from "@chakra-ui/react";
import React from "react";
export const MockPush = ({image,title = "כותרת",body = "תוכן",appName}) => (
        // <di/**/v style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f3f4f6' }}>

            <div style={{ flex: 1, display: 'flex', flexDirection:'column', gap:'20px', justifyContent: 'center', alignItems: 'flex-start',   }}>
                <Text fontWeight='600'  fontSize='md'>
תצוגה מקדימה:

                </Text>
                <div style={{ width: '380px', height: '350px',padding: '20px', borderRadius: '40px' , borderBottomLeftRadius: '0px',borderBottomRightRadius: '0px', backgroundColor: 'transparent',backgroundImage: `url(${backgroundImage})`,backgroundSize: 'cover', position: 'relative', overflow: 'hidden' }}>


                    <div style={{ backgroundColor: '#626262', color: 'white', padding: '12px',margin: '12px',marginTop: '35%',borderRadius: '20px', display: 'flex', alignItems: 'center' }}>

                        {image && <img src={image} alt="Preview" style={{ width: '75px', height: '75px', borderRadius: '12px', marginLeft: '15px' }} />}
                        <div style={{flex: "1"}}>
            <span style={{width: "100%",display:'flex',justifyContent: 'space-between',fontSize: '14px',color: '#e3e3e3'}}>
          <span>{appName}</span>

            <span>1m</span>
          </span>
                            <h4 style={{ margin: 0, fontSize: '14px' , fontWeight: '600'}}>{title}</h4>
                            <p style={{ margin: 0, fontSize: '14px',wordBreak:'break-all' }}>{body}</p>
                        </div>
                    </div>
                </div>
            </div>
        // </div>
            )

