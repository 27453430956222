import {formatPriceToLetters, formatToILS, numberPercentage, prettyNumber} from "utils/numbers.utils";

const IOS = 'iOS';
const ANDROID = 'Android';
const MONTHS_NAME = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function getDataForRow(rows,rowName) {
    const data = rows.find(row => row.dimensionValues[0].value === rowName);
    if (!data) return {count:{value: 0 }, total: {value: 0}}
    const [count, total] = data?.metricValues;
    return {count, total};

}

export function getPurchaseByDevice(rows) {
    let iosTotal =0;
    let androidTotal =0;
    const ios = 'iOS';
    const android = 'Android';
    const iosData = rows.find(row => row.dimensionValues[1].value === ios)
    iosData && (iosTotal = iosData.metricValues[0].value);
    const androidData = rows.find(row => row.dimensionValues[1].value === android);
    androidData && (androidTotal = androidData.metricValues[0].value );
    const totalCount = +androidTotal + +iosTotal
    const percentageAndroid = ((androidTotal / totalCount) || 0) * 100;
    const percentageIos = ((iosTotal / totalCount) || 0) * 100;
    return {
        headers: [ios, android],
        values: [iosTotal, androidTotal].map(Number),
        ios: {
            value: formatToILS(Number(iosTotal)),
            meta: numberPercentage(Math.floor(percentageIos)),
            name: ios
        },
        android: {
            name: android,
            meta: numberPercentage(Math.floor(percentageAndroid)),
            value: formatToILS(Number(androidTotal)),
        }
    }

}

export function getTotalUsers(rows) {
    const iosData = rows.find(row => row.dimensionValues[0].value === IOS);
    const androidData = rows.find(row => row.dimensionValues[0].value === ANDROID);
    const iosValue = iosData.metricValues[0]?.value
    const androidValue = androidData.metricValues[0]?.value
    const total = parseInt(iosValue) + parseInt(androidValue)
    return {
        headers: ['אייפון','אנדרואיד'],
        values: [iosValue, androidValue].map(Number),
        total: prettyNumber(total) ,
        ios: {
            meta: 'הורדות',
            value: prettyNumber(+iosData.metricValues[0]?.value),
        },
        android: {
            meta: 'הורדות',
            value: prettyNumber(+androidData.metricValues[0]?.value)
        }
    }
}

export function getPurcasheMonthData(rows) {
    // const monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; // Start with an empty string at index 0 for easier mapping by month number

    const result = {};

    rows.forEach(row => {
        const monthValue = row.dimensionValues.find(d => d.value.match(/^\d{2}$/)).value;
        const monthName = MONTHS_NAME[parseInt(monthValue)];
        const eventCount = parseInt(row.metricValues[0].value);
        const eventValue = parseFloat(row.metricValues[1].value);

        result[monthName] = {
            count: eventCount,
            value: eventValue
        };
    });
    const categories = Object.keys(result);
    const totalCountsList = Object.values(result).map(value => value.count);
    const totalAmountList = Object.values(result).map(value => value.value);
    const totalAmount = totalAmountList.reduce((acc, value) => acc + value, 0);
    return {
        categories,
        totalAmount:  prettyNumber(totalAmount),
        totalAmountDisplay: formatPriceToLetters(totalAmount),
        data: [
            {
                name: 'מספר רכישות',
                data: totalCountsList
            },
            {
                name: 'סכום רכישות',
                data: totalAmountList,
            }
        ]
    }
}

export function getDownloadByMonth(rows) {
    // const monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const result = {};

    rows.forEach(row => {
        const monthValue = row.dimensionValues[0].value;
        const monthName = MONTHS_NAME[parseInt(monthValue)];

        result[monthName] = parseInt(row.metricValues[0].value);

    });

    return {
        headers: Object.keys(result),
        totalDownloadNumber: prettyNumber(Object.values(result).reduce((acc,val) => acc + val,0)),
        data: [{   name: 'מספר הורדות',data: Object.values(result)}]
    };
}

export function getTableData(rows) {
    return rows.map(row => {
        const data = {
            count: 0,
            name: ''
        }
        data.name = row.dimensionValues[0].value;
        data.count = row.metricValues[0].value;
        return data;
    })
}

export const get7DaysTraffic = (data) => {
    const total = data?.totals[0]?.metricValues[0]?.value ?? 0;
    const daysAndCount = data.rows.reduce((total,row) => {
        const day = row?.dimensionValues[0].value;
        const count = row?.metricValues[0]?.value ?? 0;
        total.data.push(parseInt(count));
        total.days.push(day.slice(-2));
        return total;
    }, {days: [], data: []})
    return {
        total: prettyNumber(parseInt(total)),
        days: daysAndCount.days.reverse(),
        data: daysAndCount.data.reverse()

    }
}

export const getWeeklyRevenue = data => {
    const totalCount = data.totals[0]?.metricValues[0]?.value ?? 0;
    const totalPurchases = data.totals[0]?.metricValues[1]?.value ?? 0;

    const daysAndCount = data.rows.reduce((total,row) => {
        const day = row?.dimensionValues[0].value;
        const purchase = row?.metricValues[1].value;
        total.days.push(day.slice(-2));
        total.data[0].data.push(parseInt(purchase));
        return total;
    },{days: [],data:[{data:[200,300],name: 'סכום רכישות'}]})
    return {
        totalCount: parseInt(totalCount),
        totalPurchases: formatToILS(parseInt(totalPurchases)),
        days: [...daysAndCount.days,'19','20'],
        data: daysAndCount.data
    }
}