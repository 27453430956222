/*eslint-disable*/
import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  const { toggleColorMode } = useColorMode();
  return (
    <Flex
      zIndex='3'
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='space-between'
      px={{ base: "30px", md: "50px" }}
      pb='30px'>
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}>
        {" "}
        &copy; {1900 + new Date().getYear()}
        <Text as='span' fontWeight='500' ms='4px'>
          כל הזכויות שמורות ל
          <Link
            mx='3px'
            color={textColor}
            href='https://appload.ai'
            target='_blank'
            fontWeight='700'>
              appload.ai
          </Link>
        </Text>
      </Text>
      <List display='flex'>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            href='mailto:info@appload.ai?subject=%D7%AA%D7%9E%D7%99%D7%9B%D7%94%20%D7%98%D7%9B%D7%A0%D7%99%D7%AA%20%D7%90%D7%A4%D7%9C%D7%99%D7%A7%D7%A6%D7%99%D7%99%D7%AA%20%D7%A0%D7%99%D7%94%D7%95%D7%9C&body=%D7%94%D7%99%D7%99%20%D7%A4%D7%95%D7%A0%D7%94%20%D7%91%D7%A2%D7%A7%D7%91%D7%95%D7%AA%3A%0D%0A%0D%0A'>
            תמיכה טכנית
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            href='https://appload.ai/about/'>
            אודות
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            href='https://appload.ai/policy'>
            תנאי שימוש
          </Link>
        </ListItem>
        <ListItem>
          <Link
            fontWeight='500'
            color={textColor}
            href='https://appload.ai/'>
            אתר רשמי
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
