// Chakra imports
import {Box, Flex, Text, Select, useColorModeValue, Icon} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";
import React from "react";
import {FaAppStoreIos, FaShekelSign} from "react-icons/fa";
import {BiLogoPlayStore} from "react-icons/bi";

export default function Conversion(props) {
  const { chartData,title,...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}>
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='8px'>
        <Text color={textColor} fontSize='lg' fontWeight='600' mt='4px'>
          {title}
        </Text>
      </Flex>

      <PieChart
        h='100%'
        w='100%'
        chartData={chartData.values}
        chartOptions={pieChartOptions(chartData.headers)}
      />
      <Card
        bg={cardColor}
        flexDirection='row'
        flexBasis='50%'
        justifyContent="space-between"
        // boxShadow={cardShadow}
        w='100%'
        p='15px'
        px='20px'
        mt='15px'
        mx='auto'>
        <Flex direction='column' py='5px'>
          <Flex align='center'>
            <Box h='8px' w='8px' bg='brand.500' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              ml="5px"
              mb='5px'>
              אייפון
            </Text>
            <Icon w='16px' h='16px' as={FaAppStoreIos}  />

          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {chartData.ios.value}
          </Text>
          { chartData.ios.meta && <Text fontSize='lg' color={textColor} fontWeight='700'>
            {chartData.ios.meta}
          </Text>}
        </Flex>
        <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
        <Flex direction='column' py='5px' me='10px'>
          <Flex justify="center" align='center'>
            <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px' />
            <Text
              fontSize='xs'
              color='secondaryGray.600'
              fontWeight='700'
              ml="5px"
              mb='5px'>
              אנדרואיד
            </Text>
            <Icon w='16px' h='16px' as={BiLogoPlayStore}  />
          </Flex>
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {chartData.android.value}
          </Text>
          {chartData.android.meta &&
          <Text fontSize='lg' color={textColor} fontWeight='700'>
            {chartData.android.meta}
          </Text>}
        </Flex>
      </Card>
    </Card>
  );
}
