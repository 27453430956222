import { collection, getDoc,doc } from "firebase/firestore";
import {firebaseDB} from "firebaseConfig/config";

async function getUserData(uid) {
    const user = await getDoc(doc(firebaseDB,'users',uid))
    return user.data();
}

export const userService = {
    getUserData
}