// Chakra imports
import {
    Box, Button,
    Flex,
    Icon,
    Progress, SimpleGrid,
    Text,
    useColorModeValue, useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import Menu from "components/menu/MainMenu";
import React, {useMemo} from "react";
// Assets
import { MdOutlineCloudDone } from "react-icons/md";
import {formatToILS} from "utils/numbers.utils";
import moment from "moment";
import {FaAppStoreIos} from "react-icons/fa";
import {AiFillAndroid, AiFillApple} from "react-icons/ai";

export default function Banner(props) {
  const { plan, billingCycle, price,startLicense,platform,nativeLinks} = props;
  // Chakra Color Mode
    const toast = useToast()
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  const box = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const getTotalMonthPayments = (date) => {
      // Current date
      const currentDate = moment();

// Target date
      const targetDate = moment(date);

// Calculate the difference in months
      return  currentDate.diff(targetDate, 'months');
  }

  const billing = useMemo(() => {
      if (billingCycle === 'monthly') {
          return 'חודשי'
      } else {
          return 'שנתי'
      }
  },[billingCycle])

  const amount = useMemo(() => billingCycle !== 'monthly' ? formatToILS(parseInt(price) * 12) : formatToILS(parseInt(price)),[price,billingCycle])

  const totalPaymentByMonth  = useMemo(() => billingCycle !== 'monthly' ? '1' : getTotalMonthPayments(startLicense) ,[startLicense]);
  const totalPayment = useMemo(() => formatToILS(totalPaymentByMonth * parseInt(price)) ,[totalPaymentByMonth,price]);
  const platformList = useMemo(() => {
      return platform.map(p => p === 'android' ? 'אנדרואיד' : 'אייפון');
  },[platform])

    const paymentLabel = useMemo(() => billingCycle !== 'monthly' ? "לשנה כולל מע״מ" : 'לחודש כולל מע״מ',[billingCycle])
  return (
    <Card h="fit-content" mb={{ base: "0px", lg: "20px" }} p="30px" align='center'>
      {/*<Flex w='100%'>*/}
      {/*  <Menu ms='auto' />*/}
      {/*</Flex>*/}
      {/*<IconBox*/}
      {/*  mx='auto'*/}
      {/*  h='100px'*/}
      {/*  w='100px'*/}
      {/*  icon={*/}
      {/*    <Icon as={MdOutlineCloudDone} color={brandColor} h='46px' w='46px' />*/}
      {/*  }*/}
      {/*  bg={box}*/}
      {/*/>*/}
        <SimpleGrid  columns={{ base: 1, md: 2}}
                     gap='15px'
                     mb='20px'

        >
            <Flex flexDirection="column" alignItems="flex-start" gap="5px">

            <Text
                fontSize="sm"
                color={textColorSecondary}>
                החבילה שלי
            </Text>
            <Text color={brandColor} fontWeight='bold' fontSize='2xl' >
                {plan}
            </Text>
                <Text
                    fontSize="sm"
                    color={textColorSecondary}>
                    מחזור חיוב
                </Text>
                <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' >
                    {billing}
                </Text>
                <Text
                    fontSize="sm"
                    color={textColorSecondary}>
                    סכום חיוב
                </Text>
                <Flex alignItems="center" gap="5px"  alignContent="center">
                    <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' >
                        {amount}
                    </Text>
                    <Text color={textColorPrimary} fontWeight='200' fontSize='sm' >
                        / {paymentLabel}
                    </Text>

                </Flex>

                <Text
                    fontSize="sm"
                    color={textColorSecondary}>
                    סכום הוצאות
                </Text>
                <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' >
                    {billingCycle !== 'monthly' ? amount : totalPayment}
                </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="flex-start" gap="10px">

                <Text
                    fontSize="sm"
                    color={textColorSecondary}>
                    פלטפורמות
                </Text>
                {platformList.map((p,idx) => (
                    <Flex gap="5px">
                        <Icon key={idx} as={platform[idx] === 'android' ? AiFillAndroid : AiFillApple}  h='20px' w='20px' />

                <Text key={idx} color={textColorPrimary} fontWeight='bold' fontSize='sm'>
                    {p}
                </Text>
                  </Flex>

                ))}
                <Text mt="15px"
                    fontSize="sm"
                    color={textColorSecondary}>
                    תשלומים עד כה
                </Text>
                <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' >
                    {totalPaymentByMonth}
                </Text>

            </Flex>
        </SimpleGrid>



    </Card>
  );
}
/*
     <Box w='100%' mt='auto'>
        <Flex w='100%' justify='space-between' mb='10px'>
          <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
            {used} GB
          </Text>
          <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
            {total} GB
          </Text>
        </Flex>
        <Progress
          align='start'
          colorScheme='brandScheme'
          value={(used / total) * 100}
          w='100%'
        />
      </Box>
 */