import {createContext, useCallback, useContext, useEffect, useReducer} from "react";
import {  signInWithEmailAndPassword, onAuthStateChanged   } from 'firebase/auth';
import {firebaseAuth} from "firebaseConfig/config";
import {userService} from "services/user.service";
import {getToken} from "services/analytics.service";


export const StoreContext = createContext();

export const useStoreContext = () => useContext(StoreContext);


export const STORE_TYPES = {
    user: 'USER',
    statsData: 'STATS_DATA',
    dashboardData: 'DASHBOARD_DATA',
    userData: 'USER_DATA',
    pushData: 'PUSH_DATA',
}

const initialState = {
    user: null,
    statsData: null,
    dashboardData: null,
    userData: null,
    pushData: null,
}
const reducer = (state,action) => {
    switch (action.type) {
        case STORE_TYPES.user:
            return {
                ...state,
                user: action.user,
            }
        case STORE_TYPES.statsData:
            return {
                ...state,
                statsData: {...(state.statsData ?? {}),...action.statsData}
            }
        case STORE_TYPES.dashboardData:
            return {
                ...state,
                dashboardData: {...(state.dashboardData ?? {}),...action.dashboardData}
            }
        case STORE_TYPES.userData:
            return {
                ...state,
                userData: action.userData
            }

        default:
            return state;
    }
}

export const StoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);


    const login = async (username, password) => {
        try {
            await signInWithEmailAndPassword(firebaseAuth, username, password);
        } catch (e) {
            console.log('e')
            throw e;
        }
    };
    const setUser = (user) => dispatch({type: STORE_TYPES.user, user});

    const logout = async () => {
        await firebaseAuth.signOut();
    };


    useEffect(()=>{

        onAuthStateChanged(firebaseAuth, async (user) => {
            if (user) {

              const uid = user.uid;

                const userData = await userService.getUserData(uid);
                localStorage.setItem('viewId', userData.configs.analytics.viewId)
                await getToken(userData.configs.analytics.client_email,userData.configs.analytics.private_key.replaceAll('\n',''))
                setUser({id:uid,...user,...userData});

            } else {
                // User is signed out
                // ...
                // logout();
                setUser(null);
            }
        });

    }, [])

    return (
    <StoreContext.Provider value={{state, dispatch ,auth: {logout, login}}}>
        {children}
    </StoreContext.Provider>)
}