import moment from 'moment';
export function getWelcomeMessage() {
    const now = moment();
    const hour = now.hour();

    let message = "";

    if (hour >= 5 && hour < 12) {
        message = "בוקר טוב";
    } else if (hour >= 12 && hour < 17) {
        message = "צהריים טובים";
    } else if (hour >= 17 && hour < 22) {
        message = "ערב טוב";
    } else {
        message = "לילה טוב";
    }

    return message;
}
export function getTimeString() {
    const now = moment();
    const hour = now.hour();

    let message = "morning";

    if (hour >= 5 && hour < 12) {
        message = "morning";
    } else if (hour >= 12 && hour < 17) {
        message = "afternoon";
    } else if (hour >= 17 && hour < 22) {
        message = "evening";
    } else {
        message = "night";
    }

    return message;
}

