import axios from 'axios';
import {analyticsPayloads} from "utils/analytics.utils";

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/analytics/report`;
const getUri = () => `${BASE_URL}/${localStorage.getItem('viewId')}?token=${localStorage.getItem('token')}&expire=${localStorage.getItem('expire')}`;
const getHeaders = () => ({ headers: { Authorization: 'Bearer ' + localStorage.getItem('token_0') } })

export async function getEventData() {
    try {
        const resposne = await axios.post(getUri(), analyticsPayloads.eventDataRequestPayload, getHeaders());
        return resposne.data.data;
    } catch (e) {
        console.error(e);
    }
}

export async function topTenViewsProductsData() {
    try {
        const resposne = await axios.post(getUri(), analyticsPayloads.topTenViewsRequestPayload, getHeaders());
        return resposne.data.data;
    } catch (e) {
        console.error(e);
    }
}

export async function topProductsSellerData() {
    try {
        const resposne = await axios.post(getUri(), analyticsPayloads.topTenMostSellersRequestPayload, getHeaders());
        return resposne.data.data;
    } catch (e) {
        console.error(e);
    }
}


export async function active7DaysUsersData() {
    try {
        const resposne = await axios.post(getUri(), analyticsPayloads.active7DaysUsersRequestPayload, getHeaders());
        return resposne.data.data;
    } catch (e) {
        console.error(e);
    }
}

export async function userByDeviceData() {
    try {
        const resposne = await axios.post(getUri(), analyticsPayloads.usersByDeviceRequestPayload, getHeaders());
        return resposne.data.data;
    } catch (e) {
        console.error(e);
    }
}

export async function purchaseByDeviceData() {
    try {
        const resposne = await axios.post(getUri(), analyticsPayloads.purchaseByDeviceRequestPayload, getHeaders());
        return resposne.data.data;
    } catch (e) {
        console.error(e);
    }
}

export async function topTenAddToCartData() {
    try {
        const resposne = await axios.post(getUri(), analyticsPayloads.topTenAddToCartRequestPayload, getHeaders());
        return resposne.data.data;
    } catch (e) {
        console.error(e);
    }
}

export async function purchaseByMonthData() {
    try {
        const resposne = await axios.post(getUri(), analyticsPayloads.purchaseByMonthRequestPayload, getHeaders());
        return resposne.data.data;
    } catch (e) {
        console.error(e);
    }
}

export async function downloadsByMonthData() {
    try {
        const resposne = await axios.post(getUri(), analyticsPayloads.downloadsByMonthRequestPayload, getHeaders());
        return resposne.data.data;
    } catch (e) {
        console.error(e);
    }
}

export async function getToken(client_email, private_key) {
    try {
        const base64 = btoa(`${client_email}:${private_key}`)
        localStorage.setItem('token_0', base64)
        const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/analytics/token`, { headers: {Authorization: 'Bearer ' + base64}});
        localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('expire', response.data.expire);
    } catch (e) {
        console.error(e);
    }
}