import React from "react";

// Chakra imports
import {Flex, useColorMode, useColorModeValue} from "@chakra-ui/react";

// Custom components
import {HorizonLogo, ApploadLogo, ApploadLogoDark} from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
    const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex align='center' direction='column'>
        {colorMode === 'light' ? <ApploadLogo h='100px' w='60%' my='32px' m='0' color={logoColor} /> : <ApploadLogoDark  h='100px' w='60%' my='32px' m='0' color={logoColor} />}
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
