import { collection,updateDoc, addDoc,doc ,query,where,getDocs,} from "firebase/firestore";
import {firebaseDB} from "firebaseConfig/config";
import axios from "axios";

async function getPushDataByUid(uid) {
    try {
        const pushCollectionRef = collection(firebaseDB, 'push');
        const q = query(pushCollectionRef, where('uid', '==', doc(firebaseDB,'users',uid)));

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return null;
        } else {
            // Assuming there's only one matching document, you can access it like this
            const matchingDoc = querySnapshot.docs[0];
            const pushData = matchingDoc.data();
            return {
                id:matchingDoc.id ,
                ...pushData
            };
        }
    } catch (e) {
        console.error(e)
    }

}

async function sendPush(firebaseDoc,uid,pushData,key) {
    const apiUrl = process.env.REACT_APP_BASE_API_URL;
    try {
        await axios.post(`${apiUrl}/push/send?key=${key}`,pushData)
        const createdAt= new Date().toISOString()
        if (!firebaseDoc) {
            const userRef =  doc(firebaseDB,'users',uid);
            const newDoc = {
                notifications: [{...pushData,createdAt}],
                uid: userRef
            }
            await addDoc(collection(firebaseDB, 'push'),newDoc)
            return newDoc
        } else {
            const {id, ...restPushData} = firebaseDoc;
            const pushDocRef = doc(firebaseDB, 'push', id);
            restPushData.notifications.push({...pushData, createdAt})
            const { notifications } = restPushData;
            await updateDoc(pushDocRef,{notifications});
            return {
                id,
                ...restPushData
            }
        }
    } catch (e) {
        console.error(e)
    }

}

export const pushService = {
    getPushDataByUid,
    sendPush
}