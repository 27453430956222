import Card from "components/card/Card";
import {Flex, Icon, Link, Stack, Text, useColorModeValue} from "@chakra-ui/react";
import React from "react";
import {IoIosArrowBack} from "react-icons/io";
import {HorizonLogo} from "components/icons/Icons";
import {useHistory} from "react-router-dom";

export function CardButton(props) {
    const { icon, text, link } = props;
    const history = useHistory()
    const brandColor = useColorModeValue("brand.500", "white");
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = "secondaryGray.600";
    return (
        // <Link
        //     href={`/admin/stats`}
        //
        //     >

        <Card cursor="pointer" onClick={() => history.push('/admin/stats')} width="300px" borderRadius="10px" border="1px solid" borderColor={brandColor} py='15px'>
            <Flex justifyContent="space-between" alignItems="center">
                <Flex gap="5px" alignItems="center">
                {icon}
                <Text      ms='6px'
                           fontSize='md'
                           fontWeight='500'>
                    {text}
                </Text>
                </Flex>
                <Icon w='32px' h='32px' as={IoIosArrowBack} color={brandColor} />
            </Flex>
        </Card>
        // </Link>

    )
}