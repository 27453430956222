/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box, Flex,
    Icon,
    SimpleGrid, Spinner, Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Assets
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    MdAddTask,
    MdAttachMoney,
    MdBarChart,
    MdFileCopy, MdOutlinePeopleAlt,
} from "react-icons/md";
import {AiFillEye, AiFillStar, AiOutlineEye, AiOutlineShoppingCart} from 'react-icons/ai';
import {IoBagCheckOutline, IoEnterOutline} from 'react-icons/io5';
import { TbTargetArrow } from 'react-icons/tb'
import { FaShekelSign } from 'react-icons/fa'

import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";

import { formatToILS, getConversionRate, prettyNumber} from "utils/numbers.utils";
import {
    get7DaysTraffic,
    getDataForRow,
    getDownloadByMonth,
    getPurcasheMonthData,
    getPurchaseByDevice, getTableData,
    getTotalUsers,
} from "utils/rows.utils";
import {
    BsBagHeart,
    BsBagPlus,
    BsBagX,
    BsCartDashFill,
    BsCartPlusFill,
    BsCartXFill,
    BsFillCartXFill
} from "react-icons/bs";
import {FaArrowsRotate} from "react-icons/fa6";
import BarCard from "views/admin/default/components/BarCard";
import DataTable from "components/Table/Table";
// import WeeklyRevenue from "views/admin/rtl/components/WeeklyRevenue";
// import {MiniProfileData} from "components/card/MiniProfileData";
// import {CardUpgrade} from "views/admin/rtl/components/CardUpgrade";
import {STORE_TYPES, useStoreContext} from "contexts/StoreContext";
import {
     downloadsByMonthData,
     purchaseByDeviceData,
    purchaseByMonthData,
     topTenAddToCartData,
} from "services/analytics.service";
import {AppStoreLogo, GooglePlayLogo} from "components/icons/Icons";
import {LuDownloadCloud} from "react-icons/lu";
import {BiAnalyse, BiPurchaseTagAlt} from "react-icons/bi";
import {FiUsers} from "react-icons/fi";

const ENUM_ROWS = {
    'Purchase' : 'purchase',
    'AddToCart': 'add_to_cart',
    'RemoveFromCart': 'remove_from_cart',
    'Checkout' : 'begin_checkout',
    'ScreenView': 'screen_view',
    'AddPaymentInfo': 'add_payment_info',
    'WishList': 'add_to_wishlist',
    'FirstOpen': 'first_open',
    'OpenApp': 'app_open',
}

export default function UserReports() {
  // Chakra Color Mode
  const store = useStoreContext();
  const [isLoading,setIsLoading] = useState(false)
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const fetchAllData  = useCallback( async () => {
      if (store.state.statsData) return;
        setIsLoading(true)
        const [purchaseByDevice,topTenAddToCart,purchaseByMonth,downloadsByMonth] = await Promise.all([
            purchaseByDeviceData(),
            topTenAddToCartData(),
            purchaseByMonthData(),
            downloadsByMonthData(),
        ])
        store.dispatch({type: STORE_TYPES.statsData , statsData: {
                purchaseByDevice ,
                topTenAddToCart,
                purchaseByMonth,
                downloadsByMonth,
            }})
      setIsLoading(false);
    },[store.state.statsData]);



  useEffect(() => {
      fetchAllData()

  },[fetchAllData])



    const data = useMemo(() => {
        if (!store.state.statsData || !store.state.dashboardData || isLoading) return null;
        const { usersByDevice,active7DaysUsers ,topTenAddToCart,topTenViews,mostSellers,downloadsByMonth,eventData,purchaseByDevice,purchaseByMonth} = {
            ...store.state.statsData,
            ...store.state.dashboardData

        }
        const totalUsersByDevice = getTotalUsers(usersByDevice.rows);
        const sevenDaysTraffic= get7DaysTraffic(active7DaysUsers);
        const topTenViewsTableData = getTableData(topTenViews?.rows ?? []);
        const mostSellersTableData = getTableData(mostSellers?.rows ?? []);
        const topTenAddToCartTableData = getTableData(topTenAddToCart.rows)
        const purchaseData =  getDataForRow(eventData.rows,ENUM_ROWS.Purchase);
        const addToCartData = getDataForRow(eventData.rows,ENUM_ROWS.AddToCart);
        const openAppData = getDataForRow(eventData.rows,ENUM_ROWS.OpenApp)
        const conversionRate = getConversionRate(parseInt(purchaseData.count.value), parseInt(openAppData.count.value))
        const checkoutData = getDataForRow(eventData.rows,ENUM_ROWS.Checkout);
        const cartLeavingData = getDataForRow(eventData.rows,ENUM_ROWS.AddPaymentInfo);
        const wishListData = getDataForRow(eventData.rows,ENUM_ROWS.WishList);
        const firstOpenData = getDataForRow(eventData.rows,ENUM_ROWS.FirstOpen);
        const purchaseDataByDevice = getPurchaseByDevice(purchaseByDevice?.rows ?? []);
        const purchaseMonthData = getPurcasheMonthData(purchaseByMonth?.rows ?? []);
        const totalDownloadByMonth = getDownloadByMonth(downloadsByMonth.rows);
        return {
            totalUsersByDevice,
            sevenDaysTraffic,
            topTenViewsTableData,
            mostSellersTableData,
            topTenAddToCartTableData,
            conversionRate,
            purchaseDataByDevice,
            purchaseMonthData,
            totalDownloadByMonth,
            purchaseData: {
                total: formatToILS(purchaseData.total.value),
                count: purchaseData.count.value,
                totalNumber: Number(purchaseData.total.value)
            },
            addToCartData: {
                total: formatToILS(addToCartData.total.value),
                count: prettyNumber(addToCartData.count.value)
            },
            openAppData: {
                total: openAppData.total.value,
                count: prettyNumber(openAppData.count.value)
            },
            checkoutData: {
                total: formatToILS(checkoutData.total.value),
                count: prettyNumber(checkoutData.count.value)
            },
            cartLeavingData: {
                total: formatToILS(cartLeavingData.total.value),
                count: prettyNumber(cartLeavingData.count.value)
            },
            wishListData: {
                total: prettyNumber(wishListData.total.value),
                count: prettyNumber(wishListData.count.value)
            },
            firstOpenData: {
                total: firstOpenData.total.value,
                count: prettyNumber(firstOpenData.count.value),
                countNumber: Number(firstOpenData.count.value)
            }

        }
    }, [store.state.statsData,store.state.dashboardData, isLoading]);

    if (!data || isLoading) return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Flex flexDirection="column" alignItems="center" justifyContent="center">
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
            </Flex>
        </Box>
        )
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>


            <SimpleGrid
                columns={{ base: 2, md: 2, lg: 3, "2xl": 3 }}
                gap='20px'
                mb='20px'
            >

            <MiniStatistics
                startContent={
                    <Icon w='40px' h='40px' as={AppStoreLogo}  />
                }
                name='הורדות במכשירי iOS'
                value={data.totalUsersByDevice.ios.value}
            />
            <MiniStatistics
                startContent={
                    <Icon w='40px' h='40px' as={GooglePlayLogo}  />
                }
                name='הורדות במכשירי Android'
                value={data.totalUsersByDevice.android.value}
            />
            <MiniStatistics
                startContent={
                    <IconBox
                        borderRadius="10px"
                        w='56px'
                        h='56px'
                        bg={boxBg}
                        icon={
                            <Icon w='32px' h='32px' as={LuDownloadCloud} color={brandColor} />

                        }
                    />
                }
                name="סה״כ הורדות בכל המכשירים"
                value={data.totalUsersByDevice.total}
            />

            <MiniStatistics
                startContent={
                    <IconBox
                        borderRadius="10px"
                        w='56px'
                        h='56px'
                        bg={boxBg}
                        icon={
                            <Icon w='32px' h='32px' as={FaShekelSign} color={brandColor} />
                        }
                    />
                }
                name='סכום רכישות'
                value={data.purchaseData.total}
            />
            <MiniStatistics
                startContent={
                    <IconBox
                        borderRadius="10px"
                        w='56px'
                        h='56px'
                        bg={boxBg}
                        icon={<Icon w='28px' h='28px' as={MdOutlinePeopleAlt} color={brandColor} />}
                    />
                }
                name='כניסות באפליקציה'
                value={data.openAppData.count}
            />
            <MiniStatistics
                startContent={
                    <IconBox
                        borderRadius="10px"
                        w='56px'
                        h='56px'
                        bg={boxBg}
                        icon={
                            <Icon w='32px' h='32px' as={BiAnalyse} color={brandColor} />
                        }
                    />
                }
                name="המרות - (Conversion Rate)" value={data.conversionRate} />
        </SimpleGrid>


        <SimpleGrid  columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
            <TotalSpent title="מכירות לפי חודשים" chartData={data.purchaseMonthData}  />
            <DailyTraffic title="כניסות לאפליקציה" chartData={data.sevenDaysTraffic} />
        </SimpleGrid>
    <SimpleGrid  columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
                 gap='20px'
                 mb='20px'
    >
        <MiniStatistics
            startContent={
                <IconBox
                    borderRadius="10px"
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={
                        <Icon w='32px' h='32px' as={BiPurchaseTagAlt} color={brandColor} />
                    }
                />
            }
            name='כמות רכישות'
            value={data.purchaseData.count}
        />


        <MiniStatistics

            startContent={
                <IconBox
                    borderRadius="10px"
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={
                        <Icon w='32px' h='32px' as={BsBagPlus} color={brandColor} />
                    }
                />
            }
            name='הוסיפו לעגלה'
            value={data.addToCartData.count}
        />
        <MiniStatistics
            startContent={
                <IconBox
                    borderRadius="10px"
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={
                        <Icon w='32px' h='32px' as={FaShekelSign} color={brandColor} />
                    }
                />
            }
            name='סכום הוספות לעגלה'
            value={data.addToCartData.total}
        />
    </SimpleGrid>
        {/*<SimpleGrid  columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>*/}
        {/*    <SimpleGrid  columns={{ base: 1, md: 3, xl: 3 }} gap='20px' mb='20px'>*/}

        {/*        <MiniStatistics*/}
        {/*            startContent={*/}
        {/*                <IconBox*/}
        {/*                    w='56px'*/}
        {/*                    h='56px'*/}
        {/*                    bg={boxBg}*/}
        {/*                    icon={*/}
        {/*                        <Icon w='32px' h='32px' as={FaShekelSign} color={brandColor} />*/}
        {/*                    }*/}
        {/*                />*/}
        {/*            }*/}
        {/*            name='סכום עגלה נטושות' value={cartLevingData.total} />*/}
        {/*        <MiniStatistics*/}
        {/*            startContent={*/}
        {/*                <IconBox*/}
        {/*                    w='56px'*/}
        {/*                    h='56px'*/}
        {/*                    bg={boxBg}*/}
        {/*                    icon={*/}
        {/*                        <Icon w='32px' h='32px' as={BsCartXFill} color={brandColor} />*/}
        {/*                    }*/}
        {/*                />*/}
        {/*            }*/}
        {/*            name='כמות נטישות עגלה'*/}
        {/*            value={cartLevingData.count}*/}
        {/*        />*/}
        {/*        <MiniStatistics*/}
        {/*            startContent={*/}
        {/*                <IconBox*/}
        {/*                    w='56px'*/}
        {/*                    h='56px'*/}
        {/*                    bg={boxBg}*/}
        {/*                    icon={*/}
        {/*                        <Icon w='32px' h='32px' as={AiFillStar} color={brandColor} />*/}
        {/*                    }*/}
        {/*                />*/}
        {/*            }*/}
        {/*            name='הוסיפו ל-WishList'*/}
        {/*            value={wishListData.count}*/}
        {/*        />*/}
        {/*    </SimpleGrid>*/}
        {/*    <WeeklyRevenue title="מחזור שבועי" chartData={weeklyRevenue} />*/}

        {/*</SimpleGrid>*/}


        <SimpleGrid  columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>

            <Flex flexDirection="column" wrap gap="20px">
                <PieCard title="רכישות לפי מכשיר" chartData={data.purchaseDataByDevice} />


            </Flex>
            <SimpleGrid  columns={{ base: 2, md: 2, xl: 2 }} gap='20px' mb='20px'>
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={IoBagCheckOutline} color={brandColor} />
                            }
                        />
                    }
                    name='התחילו צ׳קאאוט'
                    value={data.checkoutData.count}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={FaShekelSign} color={brandColor} />
                            }
                        />
                    }
                    name='סכום צקאאוט' value={data.checkoutData.total} />
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={FiUsers} color={brandColor} />
                            }
                        />
                    }
                    name="כניסות לאפליקציה ממשתמשים ייחודיים"
                    value={data.firstOpenData.count}
                />
            {/*</Flex>*/}
            {/*<Flex flexDirection="column" wrap gap="20px">*/}
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={FaShekelSign} color={brandColor} />
                            }
                        />
                    }
                    name='סכום עגלה נטושות' value={data.cartLeavingData.total} />
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={BsBagX} color={brandColor} />
                            }
                        />
                    }
                    name='כמות נטישות עגלה'
                    value={data.cartLeavingData.count}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={BsBagHeart} color={brandColor} />
                            }
                        />
                    }
                    name='הוסיפו ל-WishList'
                    value={data.wishListData.count}
                />
            {/*</Flex>*/}
            </SimpleGrid>


        </SimpleGrid>



        {/*<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} width="100%" mb="20px" gap='20px'>*/}
            {/*<BarCard title="סך הכל הורדות לפי חודשים" chartData={totalDownloadByMonth} />*/}
            {/*<SimpleGrid*/}
            {/*    columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}*/}
            {/*    gap='20px'*/}
            {/*    mb='20px'>*/}



                {/*<MiniStatistics*/}
                {/*    startContent={*/}
                {/*        <IconBox*/}
                {/*            w='56px'*/}
                {/*            h='56px'*/}
                {/*            bg={boxBg}*/}
                {/*            icon={*/}
                {/*                <Icon w='32px' h='32px' as={FaShekelSign} color={brandColor} />*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*    name='הסירו מהעגלה'*/}
                {/*    value={addToCartData.total}*/}
                {/*/>*/}
                {/*<MiniStatistics*/}
                {/*    startContent={*/}
                {/*        <IconBox*/}
                {/*            w='56px'*/}
                {/*            h='56px'*/}
                {/*            bg={boxBg}*/}
                {/*            icon={*/}
                {/*                <Icon w='32px' h='32px' as={BsCartDashFill} color={brandColor} />*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*    name='הסירו מהעגלה'*/}
                {/*    value={addToCartData.count}*/}
                {/*/>*/}




                {/*<MiniStatistics*/}
                {/*    startContent={*/}
                {/*        <IconBox*/}
                {/*            w='56px'*/}
                {/*            h='56px'*/}
                {/*            bg={boxBg}*/}
                {/*            icon={*/}
                {/*                <Icon w='32px' h='32px' as={TbTargetArrow} color={brandColor} />*/}
                {/*            }*/}
                {/*        />*/}
                {/*    }*/}
                {/*    name="החזר השקעה - (ROI)" value={roiData} />*/}

            {/*</SimpleGrid>*/}
        {/*</SimpleGrid>*/}
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} width="100%" mt="10px" mb="10px" gap='20px'>
            <PieCard title="הורדות לפי מכשיר" chartData={data.totalUsersByDevice} />

            <BarCard title="סך הכל הורדות לפי חודשים" chartData={data.totalDownloadByMonth} />
        </SimpleGrid>
      
        <Flex flexDirection="column" mt="20px" gap="20px">
            <Text
                color={brandColor}
                fontSize='24px'
                textAlign='start'
                fontWeight='700'
                mb="20px"
                lineHeight='100%'>

                סטטיסטיקות על מוצרים
            </Text>
        </Flex>

        <SimpleGrid columns={{ base: 1, md: 2, xl: 3 }} width="100%" mt="10px" mb="10px" gap='20px'>
            <DataTable columnsData={
            [
            {
                Header: "שם המוצר",
                accessor: "name",
            },
            {
                Header: "כמות",
                accessor: "count",
            },
            ]}
                   tableData={data.topTenViewsTableData}
                tableName="10 המוצרים הנצפים ביותר" />

            <DataTable
                columnsData={
                    [
                        {
                            Header: "שם המוצר",
                            accessor: "name",
                        },
                        {
                            Header: "כמות",
                            accessor: "count",
                        },
                    ]}
                tableData={data.topTenAddToCartTableData}
                tableName="10 המוצרים שהוספו לסל הכי הרבה"/>
            <DataTable
                columnsData={
                    [
                        {
                            Header: "שם המוצר",
                            accessor: "name",
                        },
                        {
                            Header: "כמות",
                            accessor: "count",
                        },
                    ]}
                tableData={data.mostSellersTableData}
                tableName="10 המוצרים הנמכרים ביותר"/>

        </SimpleGrid>


    </Box>
  );
}
