export function formatToILS(amount) {
    const options = {
        style: 'currency',
        currency: 'ILS',
        minimumFractionDigits: 0,
        maximumFractionDigits: (amount % 1 !== 0) ? 2 : 0
    };
    return new Intl.NumberFormat('he-IL', options).format(+amount);
}
export function prettyNumber(number) {
    return Number(number).toLocaleString();
}
export function numberPercentage(number) {
    return `${number}%`
}
export function calculateROI(total,spent) {
    total = Number(total)
    spent = Number(spent);
    const roi = (total / spent) * 100;
    return numberPercentage(roi);
}

export function getCheckoutLeave(checkoutAmount, purchaseAmount) {
    const amount = Number(checkoutAmount) - Number(purchaseAmount);
    return formatToILS(amount);
}

export function getConversionRate(purchaseCount,totalViews) {
    const amount = (purchaseCount / totalViews) * 100;
    return numberPercentage(amount.toFixed(2));
}
export const formatPriceToLetters = (value) => {
    if (value >= 1e9) { // billions
        return (value / 1e9).toFixed(2) + 'B';
    } else if (value >= 1e6) { // millions
        return (value / 1e6).toFixed(2) + 'M';
    } else if (value >= 1000) { // thousands
        return (value / 1000).toFixed(2) + 'K';
    }
    return value.toString();
};