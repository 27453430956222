// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import {ThemeColor} from "components/sidebar/components/ThemeColor";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
      <Stack height='100%' direction='column' mb='auto' mt='8px'>
        <Box height='100%' ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes.filter(r=>r.showSideBar)} />
            <ThemeColor />

        </Box>
      </Stack>

      {/*<Box*/}
      {/*  mt='60px'*/}
      {/*  mb='40px'*/}
      {/*  borderRadius='30px'>*/}
      {/*  <SidebarCard />*/}
      {/*</Box>*/}
    </Flex>
  );
}

export default SidebarContent;
