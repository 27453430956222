// Chakra imports
import { BsFillCheckCircleFill, FiChevronRight } from "react-icons/bs";
import "./tasks.css";

// Chakra imports
import {
  Image,
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
  Icon,
  Badge,
  Tooltip,
  useMediaQuery,
  background,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { BsLink45Deg } from "react-icons/bs";
import { AppStoreLogo, GooglePlayLogo } from "components/icons/Icons";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDocs,
  collection,
  updateDoc,
  arrayUnion,
  getDoc,
  setDoc,
  query,
  where,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBNC0n6aen4n717yQCm9uOOuX3vQL20_EA",
  authDomain: "appload-commerce-app-manger.firebaseapp.com",
  projectId: "appload-commerce-app-manger",
  storageBucket: "appload-commerce-app-manger.appspot.com",
  messagingSenderId: "1099108014671",
  appId: "1:1099108014671:web:201ca8c2ec258a18e5683a",
  measurementId: "G-P5DJ91GE69",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export default function Tasks(props) {
  const {
    name,
    email,
    phoneNumber,
    nativeLinks,
    category,
    contactName,
    imageUrl,
    appName,
    bussinessName,
    usero,
  } = props;
  const [isTablet] = useMediaQuery("(min-width: 768px)");

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );





  const [selectedTask, setSelectedTask] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [user, setUser] = useState(null);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    // Use the Firebase onAuthStateChanged listener to get the user
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [auth]);

  // Function to generate unique IDs
  function generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
  }

  useEffect(() => {
    const fetchTasks = async () => {
      if (!user) return [];

      try {
        const tasksCollectionRef = collection(db, "tasks");
        const tasksQuery = query(
          tasksCollectionRef,
          where("uid", "==", user.uid)
        );

        const taskSnapshot = await getDocs(tasksQuery);

        if (taskSnapshot.size === 0) {
          const defaultTask = {
            uid: user.uid,
            tasks: [
              {
                id: generateUniqueId(),
                title: "להעלות סטורי אפליקציה",
                shortDescription: "משימה פרקטית שתעזור לכם לקדם דרך אינסטגרם",
                description: "המשימה חוזרת על עצמה כל 3 ימים.",
                image:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png",
                status: "Ongoing",
                type: "Ongoing",
                category: "instagram",
                frequency: 3,
                startDate: new Date(Date.now() - 9 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
                subtasks: [],
              },
              {
                id: generateUniqueId(),
                title: "לשלוח קמפיין אימייל על האפליקציה",
                shortDescription: "משימה פרקטית שתעזור לכם לקדם דרך אימייל",
                description: "צריך לבצע כל 14 ימים",
                image:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png",
                status: "Ongoing",
                category: "email",
                type: "Ongoing",
                frequency: 2,
                startDate: new Date(Date.now() - 9 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
                subtasks: [],
              },
              {
                id: generateUniqueId(),
                title: "יצירת אוטומציית אימייל קבועה",
                shortDescription: "צרו אוטומציית מיילים לכל לקוח שנרשם",
                description: "אוטומציית מיילים בלה בלה",
                image:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png",
                status: "Complete",
                category: "email",
                type: "One-time",
                startDate: new Date(Date.now() - 9 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
                endDate: new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
              },
              {
                id: generateUniqueId(),
                title: "להעלות סטורי לפייסבוק",
                shortDescription: "משימה פרקטית שתעזור לכם לקדם דרך פייסבוק",

                description: "צריך לבצע כל 3 ימים",
                image:
                  "https://static-00.iconduck.com/assets.00/facebook-icon-512x512-seb542ju.png",
                status: "Ongoing",
                category: "facebook",
                type: "Ongoing",
                frequency: 3,
                startDate: new Date(Date.now() - 9 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
                subtasks: [],
              },
              {
                id: generateUniqueId(),
                title: "משימת פתיחה Appload",
                shortDescription: "כמה דברים שצריך לדעת",
                description: "כמה דברים קטנים לפני שמתחילים",
                image:
                  "https://appload.ai/wp-content/uploads/2023/07/appload-fav.png",
                status: "Pending",
                category: "app",
                type: "One-time",
                startDate: new Date(Date.now() - 9 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
                endDate: new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
              },
              {
                id: generateUniqueId(),
                title: "שים לב המכירות ירדו השבוע",
                shortDescription: "שמנו לב שהמכירות שלך ירדו השבוע",
                description: "המכירות ירדו השבוע",
                image:
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/OOjs_UI_icon_alert-yellow.svg/120px-OOjs_UI_icon_alert-yellow.svg.png",
                status: "Pending",
                category: "alert",
                type: "One-time",
                startDate: new Date(Date.now() - 9 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
                endDate: new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
              },
              {
                id: generateUniqueId(),
                title: "כדאי לך לשלוח היום פוש ללקוחות שלך",
                shortDescription:
                  "שמנו לב שהיו לך היום 549 מבקרים כדאי לך לשלוח להם הודעת Push",
                description: "549 מבקרים ....... ",
                image:
                  "https://www.berkshireaerial.com/wp-content/uploads/2019/01/facebook-recommendation-01.png",
                status: "Pending",
                category: "recommendation",
                type: "One-time",
                startDate: new Date(Date.now() - 9 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
                endDate: new Date(Date.now() + 21 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .split("T")[0],
              },
            ],
          };

          await setDoc(doc(db, "tasks", user.uid), defaultTask);
          return defaultTask.tasks;
        }

        const userTasksData = taskSnapshot.docs[0].data();
        const userTasks = userTasksData.tasks || [];

        return userTasks;
      } catch (error) {
        console.error("Error fetching tasks:", error);
        return [];
      }
    };

    const updateSubtasks = async (initialTasks) => {
      return await Promise.all(
        initialTasks.map(async (task) => {
          if (task.type !== "Ongoing") return task;

          const daysSinceStart = Math.floor(
            (new Date() - new Date(task.startDate)) / (1000 * 60 * 60 * 24)
          );
          const currentEndDates = task.subtasks.map((st) => st.endDate);
          let subtasks = [...task.subtasks];

          for (let i = 0; i <= daysSinceStart; i++) {
            if (i % task.frequency === 0) {
              const endDate = new Date(
                new Date(task.startDate).getTime() +
                  (i + 1) * 24 * 60 * 60 * 1000
              )
                .toISOString()
                .split("T")[0];

              // Check if a subtask with the same end date already exists
              if (
                !currentEndDates.includes(endDate) &&
                new Date(endDate) > new Date()
              ) {
                const uniqueId = `${endDate}-${task.title}-${Date.now()}`;
                subtasks.push({
                  id: uniqueId,
                  title: `${task.title} for ${endDate}`,
                  status: "Pending",
                  image: task.image,
                  endDate,
                });
              }
            }
          }

          // Update the subtasks property of the task
          return { ...task, subtasks };
        })
      );
    };

    const fetchData = async () => {
      const initialTasks = await fetchTasks();
      const updatedTasks = await updateSubtasks(initialTasks);

      setTasks(updatedTasks);

      const interval = setInterval(async () => {
        const updatedTasksInterval = await updateSubtasks(tasks);
        setTasks(updatedTasksInterval);
      }, 24 * 60 * 60 * 1000);

      return () => clearInterval(interval);
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  const markSubtaskComplete = async (subtaskId) => {
    const taskRef = tasks.find((task) =>
      task.subtasks?.some((subtask) => subtask.id === subtaskId)
    );
    if (taskRef) {
      const subtask = taskRef.subtasks.find((st) => st.id === subtaskId);
      subtask.status = "Completed";
      await db
        .collection("users")
        .doc(user.uid)
        .collection("tasks")
        .doc(taskRef.id)
        .update({
          subtasks: taskRef.subtasks,
        });
      setTasks((prevTasks) =>
        prevTasks.map((task) => (task.id === taskRef.id ? taskRef : task))
      );
    }
  };

  const openTaskPopup = (task) => {
    setSelectedTask(task);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedTask(null);
  };

  return (
    
    <>
    
      <div style={styles.app} className="app">
        <div className="right">
        <h1 style={{ color: "#422AFB" }}>משימות ראשונות</h1>

        <div style={styles.tasksGrid}>


        {tasks
            .filter(
              (task) =>
              task.type === "One-time" &&
                task.category !== "alert" &&
                task.category !== "info" &&
                task.category !== "recommendation"
            ) // Exclude tasks with category "alert" or "info"
            .map((task) => (
              <div
                key={task.id}
                className="task-card"
                style={styles.mainTaskCard}
                onClick={() => openTaskPopup(task)}
              >
                <img
                  src={task.image}
                  alt={task.title}
                  style={{
                    width: "90px",
                    height: "auto",
                    borderRadius: "12px",
                    padding: "10px",
                  }}
                />
                <div dir="rtl">
                  <h2 style={{ color: "#080045", fontWeight: "700" }}>
                    {task.title}
                  </h2>
                  <p>{task.shortDescription}</p>

                  {task.status === "Pending" && (
                    <p>
                      {/* סטטוס: */}
                      <span
                        style={{
                          backgroundColor: "#ffcece",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#ff0000",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        {/* {task.status} */}
                        ממתין לביצוע
                      </span>
                      <span
                        style={{
                          backgroundColor: "#ffeed3",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#4f3100",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          marginRight: "5px",
                        }}
                      >
                        משימה חד-פעמית
                      </span>
                    </p>
                  )}

                  {task.status === "Complete" && (
                    <p>
                      {/* סטטוס: */}
                      <span
                        style={{
                          backgroundColor: "#d7fff2",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#00875d",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        {/* {task.status} */}
                        הושלם
                      </span>
                    </p>
                  )}

                  {/* <p>
                  סוג משימה:
                    <span style={{ backgroundColor: "#e4ddff", padding: "4px",borderRadius: '12px' }}>
                      {task.type}
                    </span>
                  </p> */}

                  {task.type === "Ongoing" && (
                    <div>
                      <span
                        style={{
                          backgroundColor: "#e4ddff",
                          padding: "4px",
                          borderRadius: "12px",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        משימה מתמשכת
                      </span>
                      <p style={{ fontSize: "14px" }}>
                        תזכורת לביצוע כל
                        <span
                          style={{
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            fontWeight: "800",
                          }}
                        >
                          {task.frequency}
                        </span>
                        ימים
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {/* ----------------------- */}

            <h1 style={{ color: "#422AFB" ,width: "100%"}}>משימות מתמשכות</h1>

        {tasks
            .filter(
              (task) =>
              task.type === "Ongoing" &&
                task.category !== "alert" &&
                task.category !== "info" &&
                task.category !== "recommendation"
            ) // Exclude tasks with category "alert" or "info"
            .map((task) => (
              <div
                key={task.id}
                className="task-card"
                style={styles.mainTaskCard}
                onClick={() => openTaskPopup(task)}
              >
                <img
                  src={task.image}
                  alt={task.title}
                  style={{
                    width: "90px",
                    height: "auto",
                    borderRadius: "12px",
                    padding: "10px",
                  }}
                />
                <div dir="rtl">
                  <h2 style={{ color: "#080045", fontWeight: "700" }}>
                    {task.title}
                  </h2>
                  <p>{task.shortDescription}</p>

                  {task.status === "Pending" && (
                    <p>
                      {/* סטטוס: */}
                      <span
                        style={{
                          backgroundColor: "#ffcece",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#ff0000",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        {/* {task.status} */}
                        ממתין לביצוע
                      </span>
                      <span
                        style={{
                          backgroundColor: "#ffeed3",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#4f3100",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          marginRight: "5px",
                        }}
                      >
                        משימה חד-פעמית
                      </span>
                    </p>
                  )}

                  {task.status === "Complete" && (
                    <p>
                      {/* סטטוס: */}
                      <span
                        style={{
                          backgroundColor: "#d7fff2",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#00875d",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        {/* {task.status} */}
                        הושלם
                      </span>
                    </p>
                  )}

                  {/* <p>
                  סוג משימה:
                    <span style={{ backgroundColor: "#e4ddff", padding: "4px",borderRadius: '12px' }}>
                      {task.type}
                    </span>
                  </p> */}

                  {task.type === "Ongoing" && (
                    <div>
                      <span
                        style={{
                          backgroundColor: "#e4ddff",
                          padding: "4px",
                          borderRadius: "12px",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        משימה מתמשכת
                      </span>
                      <p style={{ fontSize: "14px" }}>
                        תזכורת לביצוע כל
                        <span
                          style={{
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            fontWeight: "800",
                          }}
                        >
                          {task.frequency}
                        </span>
                        ימים
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {/* ----------------------- */}
            
            <h1 style={{ color: "#422AFB" ,width: "100%"}}>כל המשימות</h1>
           

          {tasks
            .filter(
              (task) =>
                task.category !== "alert" &&
                task.category !== "info" &&
                task.category !== "recommendation"
            ) // Exclude tasks with category "alert" or "info"
            .map((task) => (
              <div
                key={task.id}
                className="task-card"
                style={styles.mainTaskCard}
                onClick={() => openTaskPopup(task)}
              >
                <img
                  src={task.image}
                  alt={task.title}
                  style={{
                    width: "90px",
                    height: "auto",
                    borderRadius: "12px",
                    padding: "10px",
                  }}
                />
                <div dir="rtl">
                  <h2 style={{ color: "#080045", fontWeight: "700" }}>
                    {task.title}
                  </h2>
                  <p>{task.shortDescription}</p>

                  {task.status === "Pending" && (
                    <p>
                      {/* סטטוס: */}
                      <span
                        style={{
                          backgroundColor: "#ffcece",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#ff0000",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        {/* {task.status} */}
                        ממתין לביצוע
                      </span>
                      <span
                        style={{
                          backgroundColor: "#ffeed3",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#4f3100",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          marginRight: "5px",
                        }}
                      >
                        משימה חד-פעמית
                      </span>
                    </p>
                  )}

                  {task.status === "Complete" && (
                    <p>
                      {/* סטטוס: */}
                      <span
                        style={{
                          backgroundColor: "#d7fff2",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#00875d",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        {/* {task.status} */}
                        הושלם
                      </span>
                    </p>
                  )}

                  {/* <p>
                  סוג משימה:
                    <span style={{ backgroundColor: "#e4ddff", padding: "4px",borderRadius: '12px' }}>
                      {task.type}
                    </span>
                  </p> */}

                  {task.type === "Ongoing" && (
                    <div>
                      <span
                        style={{
                          backgroundColor: "#e4ddff",
                          padding: "4px",
                          borderRadius: "12px",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        משימה מתמשכת
                      </span>
                      <p style={{ fontSize: "14px" }}>
                        מומלץ לביצוע כל
                        <span
                          style={{
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            fontWeight: "800",
                          }}
                        >
                          {task.frequency}
                        </span>
                        ימים
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}

          {/* Filtered tasks go here */}


        </div>
        </div>

        <div className="left">
        <h1 style={{ color: "#422AFB" }}>לוח התראות</h1>

        <div className="task-card" style={{...styles.taskCard, padding: "16px"}}>
            <h3>התראות ותזכורות למשימות</h3>

            {tasks
              .filter((task) => task.type === "Ongoing")
              .flatMap((task) =>
                task.subtasks.filter(
                  (subtask) =>
                    subtask.status === "Pending" &&
                    new Date(subtask.endDate) > new Date()
                )
              )
              .map((subtask) => (
                <div
                  style={{
                    backgroundColor: "#f1e8ff",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={subtask.id}
                >
                  <img
                    src={subtask.image}
                    alt={subtask.title}
                    style={{
                      width: "50px",
                      height: "auto",
                      borderRadius: "12px",
                      padding: "4px",
                      paddingLeft: "10px",
                    }}
                  />

                  <span style={{width:"75%"}}>{subtask.title}</span>
                  <button
                    style={styles.button2}
                    onClick={() => markSubtaskComplete(subtask.id)}
                  >
                    בוצע
                    <Icon as={BsFillCheckCircleFill} h="16px" w="16px" />
                  </button>
                </div>
              ))}
{/* ------------------------- */}
<h3>התראות והמלצות</h3>

{tasks
    .filter(
        (task) =>
        task.type === "One-time" &&
        task.status === "Pending" &&
          task.category === "alert" ||

          task.type === "One-time" &&
          task.status === "Pending" &&
          task.category === "info" ||
          
          task.type === "One-time" &&
          task.status === "Pending" &&
          task.category === "recommendation"

      ) 
      .map((task) => (
    <div
      style={{
        backgroundColor: "#f1e8ff",
        padding: "10px",
        marginBottom: "10px",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
      }}
      key={task.id}
    >
      <img
        src={task.image}
        alt={task.title}
        style={{
          width: "50px",
          height: "auto",
          borderRadius: "12px",
          padding: "4px",
          paddingLeft: "10px",
        }}
      />
      <span style={{width:"75%"}}>{task.title}</span>
      <button
        style={styles.button2}
        onClick={() => markSubtaskComplete(task.id)}
      >
        בוצע
        <Icon as={BsFillCheckCircleFill} h="16px" w="16px" />
      </button>
    </div>
  ))}
{/* ----------------------- */}
            <h3>משימות פתיחה לביצוע </h3>

            {tasks
                .filter(
                    (task) =>
                    task.type === "One-time" &&
                      task.category !== "alert" &&
                      task.category !== "info" &&
                      task.category !== "recommendation" &&
                      task.status === "Pending"
                  ) 
                  .map((task) => (
                <div
                  style={{
                    backgroundColor: "#f1e8ff",
                    padding: "10px",
                    marginBottom: "10px",
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  key={task.id}
                >
                  <img
                    src={task.image}
                    alt={task.title}
                    style={{
                      width: "50px",
                      height: "auto",
                      borderRadius: "12px",
                      padding: "4px",
                      paddingLeft: "10px",
                    }}
                  />
                  <span style={{width:"75%"}}>{task.title}</span>
                  <button
                    style={styles.button2}
                    onClick={() => markSubtaskComplete(task.id)}
                  >
                    בוצע
                    <Icon as={BsFillCheckCircleFill} h="16px" w="16px" />
                  </button>
                </div>
              ))}

            {/* --------------------- */}
          </div>

          <div className="task-card" style={styles.taskCard}>
            <h3>משימות לא תקפות</h3>
            {tasks
              .filter((task) => task.type === "Ongoing")
              .flatMap((task) =>
                task.subtasks.filter(
                  (subtask) =>
                    subtask.status === "Pending" &&
                    new Date(subtask.endDate) <= new Date()
                )
              )
              .map((subtask) => (
                <div className="task-card" style={styles.taskCard}>
                  <div key={subtask.id}>
                    <span>{subtask.title}</span>
                    <span style={{ color: "red", marginLeft: "10px" }}>
                      Expired on {subtask.endDate}
                    </span>
                  </div>
                </div>
              ))}
          </div>

          <div className="task-card" style={styles.taskCard}>
            <h3>משימות שהושלמו</h3>
            {tasks
              .filter((task) => task.type === "Ongoing")
              .flatMap((task) =>
                task.subtasks.filter(
                  (subtask) => subtask.status === "Completed"
                )
              )
              .map((subtask) => (
                <div key={subtask.id}>
                  <span>{subtask.title}</span>
                </div>
              ))}
          </div>


        </div>


        {showPopup && (
          <div style={styles.overlay} onClick={closePopup}>
            <div style={styles.taskPopup} className="task-popup">
              <img
                src={selectedTask?.image}
                alt={selectedTask?.title}
                style={{
                  width: "120px",
                  height: "auto",
                  borderRadius: "12px",
                  padding: "10px",
                }}
              />
              <h2 style={{ fontSize: "24px", fontWeight: "700" }}>
                {selectedTask?.title}
              </h2>
              <p>{selectedTask?.description}</p>

              {selectedTask?.status === "Pending" && (
                    <p>
                      {/* סטטוס: */}
                      <span
                        style={{
                          backgroundColor: "#ffcece",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#ff0000",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        {/* {selectedTask?.status} */}
                        ממתין לביצוע
                      </span>
                      <span
                        style={{
                          backgroundColor: "#ffeed3",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#4f3100",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          marginRight: "5px",
                        }}
                      >
                        משימה חד-פעמית
                      </span>
                    </p>
                  )}

                  {selectedTask?.status === "Complete" && (
                    <p>
                      {/* סטטוס: */}
                      <span
                        style={{
                          backgroundColor: "#d7fff2",
                          padding: "4px",
                          borderRadius: "12px",
                          color: "#00875d",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        {/* {selectedTask?.status} */}
                        הושלם
                      </span>
                    </p>
                  )}

                  {/* <p>
                  סוג משימה:
                    <span style={{ backgroundColor: "#e4ddff", padding: "4px",borderRadius: '12px' }}>
                      {selectedTask?.type}
                    </span>
                  </p> */}

                  {selectedTask?.type === "Ongoing" && (
                    <div>
                      <span
                        style={{
                          backgroundColor: "#e4ddff",
                          padding: "4px",
                          borderRadius: "12px",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                        }}
                      >
                        משימה מתמשכת
                      </span>
                      <p style={{ fontSize: "14px" }}>
                        מומלץ לביצוע כל
                        <span
                          style={{
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            fontWeight: "800",
                          }}
                        >
                          {selectedTask?.frequency}
                        </span>
                        ימים
                      </p>
                    </div>
                  )}

              {selectedTask?.subtasks && (
                <>
                  <h3>משימות לביצוע עד מחר:</h3>
                  {selectedTask?.subtasks.map((subtask) => (
                    <div
                      key={subtask.id}
                      style={{
                        backgroundColor: "#f1e8ff",
                        padding: "10px",
                        marginBottom: "10px",
                        borderRadius: "12px",
                        width: "80%",
                      }}
                    >
                      <span>{subtask.title}</span>
                      <p>
                        סטטוס:
                        <span
                          style={{
                            backgroundColor: "#e4ddff",
                            padding: "5px",
                            borderRadius: "12px",
                          }}
                        >
                          {subtask.status}
                        </span>
                      </p>
                      {subtask.status === "Pending" && (
                        <button style={styles.button2}>
                          בוצע
                          <Icon as={BsFillCheckCircleFill} h="16px" w="16px" />
                        </button>
                      )}
                    </div>
                  ))}
                </>
              )}
              <button
                style={{
                  ...styles.button,
                  backgroundColor: "white",
                  marginTop: "20px",
                  borderRadius: "12px",
                  borderColor: "#422AFB",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  color: "#422AFB",
                }}
                onClick={closePopup}
              >
                סגירה
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

const styles = {
  app: {
    fontFamily: "Rubik, sans-serif",
    padding: "20px",
    margin: "0 auto",
    marginTop: "100px",
  },
  tasksGrid: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "20px",
  },
  mainTaskCard: {
    width: "calc(50% - 10px)",
    border: "1px solid #e0e0e0",
    borderRadius: "14px",
    padding: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.01)",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
  },
  taskCard: {
    width: "100%",
    border: "1px solid #e0e0e0",
    borderRadius: "14px",
    padding: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.01)",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
  image: {
    maxWidth: "100%",
    borderRadius: "8px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#422AFB",
    color: "#ffffff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginTop: "10px",
  },
  button2: {
    padding: "4px",
    backgroundColor: "#422AFB",
    color: "#ffffff",
    border: "none",
    borderRadius: "114px",
    cursor: "pointer",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
  taskPopup: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "18px",
    maxHeight: "80%",
    overflowY: "auto",
    zIndex: 1001,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
};
