import {
    Box,
    Input,
    FormLabel,
    FormControl,
    FormHelperText,
    Stack,
    Textarea,
    Button,
    useToast,
    Flex, Text, Spinner, SimpleGrid
} from "@chakra-ui/react";
import {IoIosSend} from "react-icons/io";
import {toast} from "react-toastify";
import {useStoreContext} from "contexts/StoreContext";
import React, {useEffect, useMemo, useState} from "react";
import {pushService} from "services/push.service";
import DataTable from "components/Table/Table";
import {getTableData} from "utils/rows.utils";
import {topTenViews} from "views/admin/rtl/data";
import moment from "moment";
import {MockPush} from "views/admin/PushNotfication/mockPush";

export default function PushNotifications(props) {
    const [isLoading,setIsLoading] = useState(false)
    const [title,setTitle] = useState()
    const [body,setBody] = useState()
    const [data,setData] = useState()
    const store = useStoreContext()


    useEffect(async () => {

            const pushResponse = await pushService.getPushDataByUid(store.state.user.id);
            if (pushResponse) {
                setData({...pushResponse, uid:null})
            }
    },[]);

   const onSentPush = async () => {
       if (isLoading) return;
       setIsLoading(true)
     const res = await pushService.sendPush(data,store.state.user.id,{title,body},store.state.user.configs.push.key);
       setIsLoading(false)
     if (!res) {
         return
     }
        setData(res)
       setTitle('')
       setBody('')
       toast.success('ההודעה נשלחה בהצלחה', {
           position: "bottom-center",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: false,
           draggable: true,
           theme: "light",
       });
   }
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid pt="40px" columns={{ base: 1, md: 2, lg: 2,   }}
                         gap='50px'

                         mb='20px'>
                <Flex flexDirection="column">

            <FormControl>
                <FormLabel>כותרת:</FormLabel>
                <Input value={title} onChange={(e) => setTitle(e.target.value)} borderRadius={13} bg="white" placeholder="כותרת ההודעה" type='text' />
                <FormHelperText>כותרת אשר תוצג בהודעה לכל המכשירים.</FormHelperText>
            </FormControl>
            <FormControl>
                <FormLabel>תוכן ההודעה:</FormLabel>
                <Textarea
                    borderRadius={13}
                    bg="white"
                    value={body} onChange={(e) => setBody(e.target.value)}
                    placeholder='תוכן ההודעה אשר תוצג מתחת לכותרת'
                    size='sm'
                />
                <FormHelperText>תוכן ההודעה אשר יוצג מתחת לכותרת - מוגבל עד 100 תווים.</FormHelperText>
            </FormControl>
            {isLoading ? <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            /> : <Button mt="20px" bg="#622CF4"  size="lg" rightIcon={<IoIosSend />} color="white" _hover={{bg: '#622CF4' }}  onClick={onSentPush}
            >שלח Push</Button>}
            </Flex>
                <MockPush appName={store.state.user.appName} image={store.state.user.imageUrl} title={title} body={body} />

            </SimpleGrid>

            { data &&
            <Flex mt="30px" flexDirection="column" gap="10px">
                <Text fontWeight='bold' fontSize='xl' mb='10px'>
                    היסטוריית הודעות Push:
                </Text>
                <DataTable columnsData={
                    [
                        {
                            Header: "כותרת הודעה",
                            accessor: "title",
                        },
                        {
                            Header: "תוכן הודעה",
                            accessor: "body",
                        },
                        {
                            Header: "תאריך",
                            accessor: "createdAt",
                        },
                    ]}
                           tableData={data.notifications.map(n => ({...n, createdAt: moment(new Date(n.createdAt)).format('HH:mm - DD/MM/YYYY')}))}
                           tableName="היסטוריית הודעות" />
            </Flex>}
        </Box>
    )
}