import {Box, Heading, Icon, Stack, SimpleGrid, Flex, Text, useColorModeValue, Spinner} from "@chakra-ui/react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getWellcomeMessageWithUserName} from "utils/utils";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {FaShekelSign, FaSun} from "react-icons/fa";
import {MdBarChart, MdOutlinePeopleAlt} from "react-icons/md";
import {AiFillEye, AiOutlineCloudDownload} from "react-icons/ai";
import {
    BsBagPlus,
    BsCartPlusFill,
    BsCartXFill,
    BsFillMoonFill,
    BsFillMoonStarsFill,
    BsFillSunFill
} from "react-icons/bs";
import {getTimeString} from "utils/date.utils";
import TotalSpent from "views/admin/default/components/TotalSpent";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import {get7DaysTraffic, getDataForRow, getTableData, getTotalUsers} from "utils/rows.utils";
import DataTable from "components/Table/Table";
import {CardButton} from "views/admin/Dashboard/components/CardButton";
import {BiAnalyse, BiPurchaseTagAlt, BiShekel, BiSolidDownload} from "react-icons/bi";
import {STORE_TYPES, useStoreContext} from "contexts/StoreContext";
import {
    active7DaysUsersData,
    getEventData,
    topProductsSellerData,
    topTenViewsProductsData, userByDeviceData
} from "services/analytics.service";
import {formatToILS, getConversionRate, prettyNumber} from "utils/numbers.utils";
import { AppStoreLogo, GooglePlayLogo} from "components/icons/Icons";
import {LuDownloadCloud} from "react-icons/lu";
import {GoNumber} from "react-icons/go";
import {SiGoogleanalytics} from "react-icons/si";
import {FiShoppingBag} from "react-icons/fi";
import {TbBrandGoogleAnalytics} from "react-icons/tb";

const iconByTime = {
    morning:BsFillSunFill,
    afternoon:FaSun,
    evening:BsFillMoonFill,
    night:BsFillMoonStarsFill,
}

const ENUM_ROWS = {
    'Purchase' : 'purchase',
    'AddToCart': 'add_to_cart',
    'RemoveFromCart': 'remove_from_cart',
    'Checkout' : 'begin_checkout',
    'ScreenView': 'screen_view',
    'AddPaymentInfo': 'add_payment_info',
    'WishList': 'add_to_wishlist',
    'FirstOpen': 'first_open',
    'OpenApp': 'app_open',
}

export default function Dashboard(props) {
    const store = useStoreContext();
    const [isLoading,setIsLoading] = useState(false)

    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const helloDateString = useMemo(() => getWellcomeMessageWithUserName(store.state.user.contactName),[props?.username])
    const IconDate = useMemo(() => iconByTime[getTimeString()],[]);
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const fetchAllData  = useCallback( async () => {
        if (store?.state?.dashboardData) return;
        setIsLoading(true)
        const [eventData,topTenViewsProducts,topProductsSeller,active7DaysUsers,usersByDevice] = await Promise.all([
        getEventData(),
        topTenViewsProductsData(),
        topProductsSellerData(),
         active7DaysUsersData(),
        userByDeviceData(),
        ])
          store.dispatch({type: STORE_TYPES.dashboardData , dashboardData: {
                  active7DaysUsers ,
                  usersByDevice,
                  mostSellers: topProductsSeller,
                  topTenViews: topTenViewsProducts,
                  eventData
              }})
        setIsLoading(false)

    },[store.state.dashboardData]);
    useEffect(() => {
        fetchAllData()
    }, [fetchAllData])
    const data = useMemo(() => {
        if (!store.state.dashboardData || isLoading) return null;
        const { usersByDevice,active7DaysUsers,topTenViews,mostSellers,eventData} = store.state.dashboardData
        const totalUsersByDevice = getTotalUsers(usersByDevice.rows);
        const sevenDaysTraffic= get7DaysTraffic(active7DaysUsers);
        const topTenViewsTableData = getTableData(topTenViews?.rows ?? []);
        const mostSellersTableData = getTableData(mostSellers?.rows ?? []);
        const purchaseData =  getDataForRow(eventData.rows,ENUM_ROWS.Purchase);
        const addToCartData = getDataForRow(eventData.rows,ENUM_ROWS.AddToCart);
        const openAppData = getDataForRow(eventData.rows,ENUM_ROWS.OpenApp)
        const conversionRate = getConversionRate(parseInt(purchaseData.count.value), parseInt(openAppData.count.value))
        return {
            totalUsersByDevice,
            sevenDaysTraffic,
            topTenViewsTableData,
            mostSellersTableData,
            conversionRate,
            purchaseData: {
                total: formatToILS(purchaseData.total.value),
                count: purchaseData.count.value,
                totalNumber: Number(purchaseData.total.value)
            },
            addToCartData: {
                total: formatToILS(addToCartData.total.value),
                count: prettyNumber(addToCartData.count.value)
            },
            openAppData: {
                total: openAppData.total.value,
                count: prettyNumber(openAppData.count.value)
            }
        }
    }, [store.state.dashboardData, isLoading]);
    if (!data || isLoading) return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                <Flex gap="10px" alignItems="center">

                    <Heading as='h4' size='md' fontWeight="400">{helloDateString}</Heading>

                    <Icon w='22px' h='22px' as={IconDate} color={brandColor} />
                </Flex>
            <Heading size="md" mb="10px" mt="10px" as="h5">נתונים אחרונים:</Heading>
            <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
            </Flex>
        </Box>
    )
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Stack spacing={6}>
                <Flex gap="10px" alignItems="center">

                    <Heading as='h4' size='md' fontWeight="400">{helloDateString}</Heading>
                    <Icon w='22px' h='22px' as={IconDate} color={brandColor} />
                </Flex>
            </Stack>
            <Heading size="md" mb="10px" mt="10px" as="h3">נתונים אחרונים:</Heading>

            <SimpleGrid
                columns={{ base: 2, md: 2, lg: 3, "2xl": 3 }}
                gap='20px'
                mt="30px"
                mb='20px'>
                <MiniStatistics
                    startContent={
                        <Icon w='40px' h='40px' as={AppStoreLogo}  />

                    }
                    name='הורדות במכשירי iOS'
                    value={data.totalUsersByDevice.ios.value}
                />
                <MiniStatistics
                    startContent={
                        <Icon w='40px' h='40px' as={GooglePlayLogo}  />

                    }
                    name='הורדות במכשירי Android'
                    value={data.totalUsersByDevice.android.value}
                />

                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={LuDownloadCloud} color={brandColor} />
                            }
                        />
                    }
                    name='סה״כ הורדות בכל המכשירים' value={data.totalUsersByDevice.total} />
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={FaShekelSign} color={brandColor} />
                            }
                        />
                    }
                    name='סכום רכישות'
                    value={data.purchaseData.total}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={MdOutlinePeopleAlt} color={brandColor} />
                            }
                        />
                    }
                    name='כניסות לאפליקציה'
                    value={data.openAppData.count}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={BiAnalyse} color={brandColor} />
                            }
                        />
                    }
                    name='יחס המרה'
                    value={data.conversionRate}
                />
            </SimpleGrid>
            <SimpleGrid  columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
                <DailyTraffic title="כניסות לאפליקציה" chartData={data.sevenDaysTraffic} />
                <Flex justifyContent="center"  flexDirection="column" gap="10px">
                 <Flex flexDirection="column" gap="5px">
                     <Text
                         ms='6px'
                         fontSize='25px'
                         fontWeight='700'
                         color={textColor} >עוד אנלטיקות מהאפליקציה</Text>
                     <Text
                         ms='6px'
                         color='secondaryGray.600'
                         fontSize='md'
                         fontWeight='500'>
                         יש עוד הרבה מה לראות
                     </Text>
                 </Flex>
                    <CardButton
                        icon={ <Icon w='32px' h='32px' as={TbBrandGoogleAnalytics} color={brandColor} />}
                        text="כל הנתונים" />
                    <CardButton
                        icon={ <Icon w='32px' h='32px' as={FiShoppingBag} color={brandColor} />}
                        text="המוצרים הכי נמכרים" />
                </Flex>
            </SimpleGrid>
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
                gap='20px'
                mt="30px"
                mb='20px'>
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={BiPurchaseTagAlt} color={brandColor} />
                            }
                        />
                    }
                    name='כמות רכישות'
                    value={data.purchaseData.count}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            borderRadius="10px"
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={BsBagPlus} color={brandColor} />
                            }
                        />
                    }
                    name='הוסיפו לעגלה'
                    value={data.addToCartData.count}
                />

                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={FaShekelSign} color={brandColor} />
                            }
                        />
                    }
                    name='סכום הוספות לעגלה'
                    value={data.addToCartData.total} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} width="100%" mt="10px" mb="10px" gap='20px'>
                <DataTable columnsData={
                    [
                        {
                            Header: "שם המוצר",
                            accessor: "name",
                        },
                        {
                            Header: "כמות",
                            accessor: "count",
                        },
                    ]}
                           tableData={data.topTenViewsTableData}
                           tableName="10 המוצרים הנצפים ביותר" />

                <DataTable
                    columnsData={
                        [
                            {
                                Header: "שם המוצר",
                                accessor: "name",
                            },
                            {
                                Header: "כמות",
                                accessor: "count",
                            },
                        ]}
                    tableData={data.mostSellersTableData}
                    tableName="10 המוצרים הנמכרים ביותר"/>

            </SimpleGrid>
        </Box>
    )
}