// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from  "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBNC0n6aen4n717yQCm9uOOuX3vQL20_EA",
    authDomain: "appload-commerce-app-manger.firebaseapp.com",
    projectId: "appload-commerce-app-manger",
    storageBucket: "appload-commerce-app-manger.appspot.com",
    messagingSenderId: "1099108014671",
    appId: "1:1099108014671:web:201ca8c2ec258a18e5683a",
    measurementId: "G-P5DJ91GE69"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const firebaseDB = getFirestore(app)
export const firebaseAuth = getAuth(app);
