import React, {useEffect, useMemo} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider  } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/App.css';
import {StoreProvider, useStoreContext} from "contexts/StoreContext";
import {isEmpty} from 'lodash';

const App = () => {
	const store = useStoreContext();
	const {user} = store?.state
	const userExist = useMemo(() => !isEmpty(user),[user])



	return (
		<Router>
			<Switch>
				{!userExist && <Route path={`/auth`} component={AuthLayout}/>}
				{userExist && <Route path={`/admin`} component={RtlLayout} />}
				{userExist && <Redirect from='/auth/login' to='/admin' />}
				{!userExist && <Redirect from='**' to='/auth/login' />}

			</Switch>
		</Router>

	)
}
ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
				<StoreProvider>
					<App />
				</StoreProvider>
				<ToastContainer />
			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>
	,
	document.getElementById('root')
);
