// Chakra imports
import {
    Image,
    Box,
    Button,
    Flex,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
    Icon,
    Badge,
    Tooltip, useMediaQuery
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import {toast} from "react-toastify";
import {FaAppStoreIos, FaGooglePlay} from "react-icons/fa";
import {BsLink45Deg} from "react-icons/bs";
import {AppStoreLogo, GooglePlayLogo} from "components/icons/Icons";
import {GoDotFill} from "react-icons/go";

export default function Banner(props) {
  const {  name,
      email,
      phoneNumber,
      nativeLinks,
      category ,contactName,imageUrl,appName,bussinessName} = props;
    const [isTablet] = useMediaQuery('(min-width: 768px)')

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

    const onCopyLink = async (text) => {
        await navigator.clipboard.writeText(text);
        toast.success('קישור הועתק בהצלחה', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            pauseOnHover: false,
            theme: "light",
        });
    }

    const onOpenLink =  (link) => window.open(link,'_blank');
    const propsImg = {
        width: '120px',
        height: '120px',
        borderRadius:'22px',
        alignSelf: 'start',
        justifySelf: 'center',
    }
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center' p="30px">
        <SimpleGrid   columns={{ base: 2, md: 2, lg: 2, "2xl": 2    }}
                      gap='30px'

                      mb='20px'>

      {/*<Box*/}
      {/*  bg={`url(${banner})`}*/}
      {/*  bgSize='cover'*/}
      {/*  borderRadius='16px'*/}
      {/*  h='131px'*/}
      {/*  w='100%'*/}
      {/*/>*/}
            <Image
                src={imageUrl}
                bgSize="cover"
                alignSelf="flex-start"
                borderRadius="40px"
                alt='logo'
                mt= '13px'
                {...(isTablet ? {} : propsImg)}
            />

            <Flex alignItems="flex-start" flexDirection="column" gap="2px">
                <Text color={textColorSecondary} fontWeight='bold' fontSize='md' mt='10px'>
שם אפליקציה
                </Text>
                <Text _first={{letter: 'uppercase'}} color={textColorPrimary} fontWeight='bold' fontSize='38px' >
                    {appName}
                </Text>
                <Text color={textColorSecondary} fontWeight='bold' fontSize='md' mt='10px'>
                    שם איש קשר
                </Text>
                <Text color={textColorPrimary} fontWeight='bold' fontSize='md' >
                    {contactName}
                </Text>
                <Text color={textColorSecondary} fontWeight='bold' fontSize='md' mt='10px'>
                    שם העסק
                </Text>
          <Text color={textColorPrimary} fontWeight='bold' fontSize='md' >
                  {bussinessName}
             </Text>
                <Text color={textColorSecondary} fontWeight='bold' fontSize='md' >
                  אימייל
                </Text>
      <Text color={textColorPrimary} fontSize='sm'>
          {email}
      </Text>
                <Text color={textColorSecondary} fontWeight='bold' fontSize='md' >
                    טלפון
                </Text>
                <Text color={textColorPrimary} fontSize='sm'>
                    {phoneNumber}
                </Text>
                {/*<Text color={textColorSecondary} fontWeight='bold' fontSize='md' mt='10px'>*/}
                {/*    קטגוריה*/}
                {/*</Text>*/}
                {/*<Text color={textColorPrimary} fontWeight='bold' fontSize='md' >*/}
                {/*    {category.map(c => c === 'shop' ? 'קניות' : c).join(' ,')}*/}
                {/*</Text>*/}
            </Flex>
            <Flex  gridColumn="1/-1" alignItems="flex-start" justifyContent="center" flexDirection="column" gap="5px">
                <Text color={textColorSecondary} fontWeight='bold' fontSize='md' mt='10px'>
                    קטגוריה
                </Text>
                <Text color={textColorPrimary} fontWeight='bold' fontSize='md' >
                    {category.map(c => c === 'shop' ? 'קניות' : c).join(' ,')}
                </Text>
                <Text color={textColorSecondary} fontWeight='bold' fontSize='md' mt='10px'>
                    חנויות פעילות
                </Text>
                <Card display="flex" gap="5px" border="1px solid" borderColor={textColorSecondary}  padding="20px">
                    <Flex alignItems="center" justifyContent="space-between" gap="5px">
                        <Box display="flex" alignItems="center" gap="10px">
                            <Icon as={AppStoreLogo}  h='32px' w='32px' />
                            <Text color={textColorPrimary} fontWeight='400' fontSize='20px' >
                                App Store
                            </Text>
                        </Box>
                        <Flex alignItems="center" >

                        <Badge ml='1' fontSize='0.8em' gap="5px" colorScheme='green'>
                            פעיל
                        </Badge>
                        </Flex>

                    </Flex>
                    <Tooltip placement="top-end" label="פתח קישור">
                        <Text textAlign="right" cursor="pointer" onClick={()=>onOpenLink(nativeLinks.ios)} color={textColorPrimary} fontWeight='bold' fontSize='sm' >
                            {nativeLinks.ios}
                        </Text>
                    </Tooltip>
                    <Button color="#622cf4" bg='#f6f2ff' onClick={() => onCopyLink(nativeLinks.ios)} width="fit-content" leftIcon={<BsLink45Deg />} >העתק קישור</Button>

                </Card>

                <Card mt="16px" border="1px solid" borderColor={textColorSecondary} display="flex" gap="10px" padding="20px">
                    <Flex alignItems="center" justifyContent="space-between" gap="10px">
                        <Box display="flex"  gap="5px">
                            <Icon as={GooglePlayLogo}  h='32px' w='32px' />
                            <Text color={textColorPrimary} fontWeight='400' fontSize='20px' >
                                Google Play
                            </Text>
                        </Box>
                        <Badge ml='1' fontSize='0.8em' colorScheme='green'>
                            פעיל

                        </Badge>

                    </Flex>
                    <Tooltip placement="top-end" label="פתח קישור">

                        <Text textAlign="right" cursor="pointer" onClick={()=>onOpenLink(nativeLinks.android)} color={textColorPrimary} fontWeight='bold' fontSize='sm' >
                            {nativeLinks.android}
                        </Text>
                    </Tooltip>



                    <Button onClick={() => onCopyLink(nativeLinks.android)}
                            width="fit-content" leftIcon={<BsLink45Deg />} color="#622cf4" bg='#f6f2ff'>
                        העתק קישור
                    </Button>
                </Card>
            </Flex>

        </SimpleGrid>

    </Card>
  );
}

/*
   <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Posts
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Followers
          </Text>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Following
          </Text>
        </Flex>
      </Flex>

 */
