const eventDataRequestPayload = {"dimensions":[{"name":"eventName"}],"metrics":[{"name":"eventCount"},{"name":"eventValue"}],"dateRanges":[{"startDate":"360daysAgo","endDate":"today"}]}

const purchaseByDeviceRequestPayload = {"dimensions":[{"name":"eventName"},{"name":"platform"}],"metrics":[{"name":"eventValue"}],"dateRanges":[{"startDate":"360daysAgo","endDate":"today"}],"dimensionFilter":{"filter":{"stringFilter":{"matchType":"PARTIAL_REGEXP","value":"purchase"},"fieldName":"eventName"}}}

const usersByDeviceRequestPayload = {"dimensions":[{"name":"platform"}],"metrics":[{"name":"newUsers"}],"dateRanges":[{"startDate":"360daysAgo","endDate":"today"}]}

const purchaseByMonthRequestPayload = {"dimensions":[{"name":"eventName"},{"name":"month"}],"metrics":[{"name":"eventCount"},{"name":"eventValue"}],"dateRanges":[{"startDate":"360daysAgo","endDate":"today"}],"dimensionFilter":{"filter":{"stringFilter":{"matchType":"EXACT","value":"purchase"},"fieldName":"eventName"}},"orderBys":[{"dimension":{"orderType":"ALPHANUMERIC","dimensionName":"month"}}]}

const downloadsByMonthRequestPayload = {"dimensions":[{"name":"month"}],"metrics":[{"name":"newUsers"}],"dateRanges":[{"startDate":"360daysAgo","endDate":"today"}],"orderBys":[{"dimension":{"orderType":"ALPHANUMERIC","dimensionName":"month"}}]}

const topTenMostSellersRequestPayload = {"dimensions":[{"name":"itemName"}],"metrics":[{"name":"itemsPurchased"}],"dateRanges":[{"startDate":"360daysAgo","endDate":"today"}],"limit":"10","orderBys":[{"metric":{"metricName":"itemsPurchased"},"desc":true}]}

const topTenAddToCartRequestPayload = {"dimensions":[{"name":"itemName"}],"metrics":[{"name":"itemsAddedToCart"}],"dateRanges":[{"startDate":"360daysAgo","endDate":"today"}],"limit":"10","orderBys":[{"metric":{"metricName":"itemsAddedToCart"},"desc":true}]}

const topTenViewsRequestPayload = {"dimensions":[{"name":"itemName"}],"metrics":[{"name":"itemsViewed"}],"dateRanges":[{"startDate":"360daysAgo","endDate":"today"}],"limit":"10","orderBys":[{"metric":{"metricName":"itemsViewed"},"desc":true}]}

const active7DaysUsersRequestPayload = {"dimensions":[{"name":"date"}],"metrics":[{"name":"active7DayUsers"}],"dateRanges":[{"startDate":"7daysAgo","endDate":"today"}],"metricAggregations":["TOTAL"]}

export const analyticsPayloads = {
    eventDataRequestPayload,
    purchaseByDeviceRequestPayload,
    usersByDeviceRequestPayload,
    purchaseByMonthRequestPayload,
    downloadsByMonthRequestPayload,
    topTenMostSellersRequestPayload,
    topTenAddToCartRequestPayload,
    topTenViewsRequestPayload,
    active7DaysUsersRequestPayload,
}