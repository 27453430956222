import {FormControl, FormLabel, Switch, useColorMode} from "@chakra-ui/react";
import {useMemo} from "react";

export const ThemeColor = (props) => {
    const { colorMode, toggleColorMode } = useColorMode();
    const label = useMemo(() => colorMode === 'light' ? 'מצב בהיר' : 'מצב כהה',[colorMode])
    return (
        <FormControl display='flex' flexDirection="column" position="fixed" bottom="50px"  gap="10px">
            <FormLabel htmlFor='email-alerts' mb='0'>
                {label}
                            </FormLabel>
            <Switch size="lg"  alignSelf="flex-start" colorScheme="purple" dir="ltr" onChange={toggleColorMode}  />
        </FormControl>
    )
}